// This should be a separate chunk for caching/performance reasons but
// it was too much of a pain to make sure it's loaded first via the webpack
// html plugin
import 'zone.js';

// https://github.com/auth0/lock/issues/1411
(window as any).global = window;

// IE11 doesn't support classList on svg elements which causes angular to break
// https://github.com/angular/angular/issues/6327
if (
    !(
        'classList' in
        document.createElementNS('http://www.w3.org/2000/svg', 'g')
    )
) {
    const descr = Object.getOwnPropertyDescriptor(
        HTMLElement.prototype,
        'classList'
    );
    Object.defineProperty(SVGElement.prototype, 'classList', descr);
}

// IE11 doesn't support Element.remove
if (!Element.prototype.remove) {
    Object.defineProperty(Element.prototype, 'remove', {
        value() {
            if (this.parentNode) {
                this.parentNode.removeChild(this);
            }
        }
    });
}

// IE11 doesn't support String.startsWith
if (!String.prototype.startsWith) {
    Object.defineProperty(String.prototype, 'startsWith', {
        value: function (search: string, pos: number) {
            return (
                this.substr(!pos || pos < 0 ? 0 : +pos, search.length) ===
                search
            );
        }
    });
}

// IE11 fix for angular animations
if (!Element.prototype.matches) {
    Element.prototype.matches = (Element.prototype as any).msMatchesSelector;
}

//IE does not support String.prototype.endsWith()
if (!String.prototype.endsWith) {
    String.prototype.endsWith = function (search, this_len) {
        if (this_len === undefined || this_len > this.length) {
            this_len = this.length;
        }
        return this.substring(this_len - search.length, this_len) === search;
    };
}

// Taken from code written by Dominic Denicola
// http://exploringjs.com/es6/ch_promises.html#sec_additional-promise-methods
if (!Promise.prototype.finally) {
    Promise.prototype.finally = function (callback: any) {
        const P: any = this.constructor;
        // We don’t invoke the callback in here,
        // because we want then() to handle its exceptions
        return this.then(
            // Callback fulfills => continue with receiver’s fulfillment or rejection
            // Callback rejects => pass on that rejection (then() has no 2nd parameter!)
            (value: any) => P.resolve(callback()).then(() => value),
            (reason: any) =>
                P.resolve(callback()).then(() => {
                    throw reason;
                })
        );
    };
}

//To fix runtime process undefined error.

(window as any).process = {
    env: { DEBUG: undefined }
};
